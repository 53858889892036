
.App-logo {
  height: 70px;
  pointer-events: none;
}
.App-logo-small {
  height: 45px;
  pointer-events: none;
  margin-left: 10px;
}

.App-logo-big {
  height: 100px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
  .App-logo-big{
    animation: App-logo-float infinite 3s ease-in-out;
  }
  .App-logo-small{
    animation: App-logo-float-small infinite 3s ease-in-out;
  }
}

.App-background {
  background-color: #282c34;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes App-logo-float-small {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0px);
  }
}

